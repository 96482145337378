import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/UI/layout/layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AuthContext from '../../stores/authContext';

function ShowGuides(props) {
    const [guide, setGuide] = useState('')
    const [tags, setTags] = useState('')
    const [content, setContent] = useState('')
	const [shownSteps, setShownSteps] = useState([])
	const { user } = useContext(AuthContext)

    useEffect(() => {
		if (user && user.token) {
			axios.get(`${process.env.REACT_APP_API_PATH}/api/guides/${props.match.params.machine_name}`, {
				headers: {
					'auth-token': user.token
				}
			}).then(res => {
				setGuide(res.data)
			})
	
			axios.get(`${process.env.REACT_APP_API_PATH}/api/guides/${props.match.params.machine_name}/tags`, {
				headers: {
					'auth-token': user.token
				}
			}).then(res => {
				setTags(res.data)
			})
	
			axios.get(`${process.env.REACT_APP_API_PATH}/api/guides/${props.match.params.machine_name}/content`, {
				headers: {
					'auth-token': user.token
				}
			}).then(res => {
				setContent(res.data.sort((a, b) => a.position > b.position ? 1 : -1))
			})
		}
    }, [props.match.params.machine_name, user])

    function handleSetShownSteps(item) {
        if (shownSteps && shownSteps.filter(i => i.id === item.id).length > 0) {
            // Remove selected option and its children from array
            const index = shownSteps.findIndex(i => parseInt(i.parent) === parseInt(item.parent))
            const stepsCopy = shownSteps.slice(0)
			shownSteps.filter(i => i.id === stepsCopy[index].id || (i.top_parent === stepsCopy[index].top_parent && i.parents.length > stepsCopy[index].parents.length)).map(step => {
				return stepsCopy.splice(stepsCopy.findIndex(i => i.id === step.id), 1)
			})
			setShownSteps(stepsCopy)
        } else if (shownSteps && shownSteps.filter(i => i.parent === item.parent).length > 0) {
            // Edit selected option in array and remove its children
			const index = shownSteps.findIndex(i => parseInt(i.parent) === parseInt(item.parent))
			const stepsCopy = shownSteps.slice(0)
			shownSteps.filter(i => i.top_parent === stepsCopy[index].top_parent && i.parents.length > stepsCopy[index].parents.length).map(step => {
				return stepsCopy.splice(stepsCopy.findIndex(i => i.id === step.id), 1)
			})
			stepsCopy[index].id = item.id
            setShownSteps(stepsCopy)
        } else {
			let top_parent = null
			let indexes = []
			let index = content.findIndex(i => i.id === item.parent)
			if (content[index]) {
				indexes.push(index)
				if (content[index].parent > 0) {
					do {
						indexes.push(content[content.findIndex(i => i.id === indexes[indexes.length - 1])].parent)
					}
					while (content[indexes[indexes.length - 1]].parent > 0)	
				}
				top_parent = indexes[indexes.length - 1]
			}
			
            // Add selected option to array
            setShownSteps(prevState => [
                ...prevState,
                { parent: item.parent, id: item.id, top_parent: top_parent, parents: indexes }
            ])
        }
	}

    function handleContent(item) {
		if (item.type === 'option-block') {
			return (
				<span
					key = { item.position } 
					className = { content[content.findIndex(i => i.id === item.parent)].parent === 0 || shownSteps.filter(i => i.id === content[content.findIndex(i => i.id === item.parent)].parent).length > 0 ? null : 'Hidden' }
				>
					<button 
						className = { shownSteps.filter(i => i.id === item.id).length > 0 ? 'Selected OptionButton ' + JSON.parse(item.value).color : 'OptionButton ' + JSON.parse(item.value).color } 
						onClick={ () => handleSetShownSteps(item) }>
						{ JSON.parse(item.value).option }
					</button>
				</span>
			)
		} else if (item.type === 'conditional-block') {
			return (
				<div
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock' : 'Hidden ContentBlock' }  
					key = { item.position }
				>
					<h2>{ item.value }</h2>
				</div>
			)
		} else if (item.type === 'image-block') {
			return (
				<div
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock' : 'Hidden ContentBlock' }  
					key = { item.position }
				>
					<img src={ `${process.env.REACT_APP_API_PATH}/images/${JSON.parse(item.value).path}` } alt={ JSON.parse(item.value).altText } />
					<figcaption>{ JSON.parse(item.value).caption }</figcaption>
				</div>
			)
		} else if (item.type === 'file-block') {
			return (
				<div
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock' : 'Hidden ContentBlock' }  
					key = { item.position }
				>
					<a href={ `${process.env.REACT_APP_API_PATH}/uploads/${JSON.parse(item.value).fileName}` } target='_blank' rel='noreferrer'>
						{ JSON.parse(item.value).label }
					</a>
				</div>
			)
		} else if (item.type === 'link-block') {
			return (
				<div
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock' : 'Hidden ContentBlock' }  
					key = { item.position }
				>
					<a href={ JSON.parse(item.value).link } target='_blank' rel='noreferrer'>
						{ JSON.parse(item.value).label }
					</a>
				</div>
			)
		} else if (item.type === 'success-block') {
			return (
				<div
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock' : 'Hidden ContentBlock' }  
					key = { item.position }
				>
					<FontAwesomeIcon icon='thumbs-up' />
					<p>{ item.value }</p>
				</div>
			)
		} else if (item.type === 'video-block' && item.value.charAt(0) === '{') {
			return (
				<div 
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock' : 'Hidden ContentBlock' }  
					key = { item.position } 
				>
					{ JSON.parse(item.value).label && <h2>{ JSON.parse(item.value).label }</h2> }
					<div 
						style={{ boxShadow: 'none'}}
						className = 'VideoBlock'
						dangerouslySetInnerHTML = {{ __html: JSON.parse(item.value).video }}>
					</div>
				</div>
			)
		} else if (item.type === 'video-block') {
			return (
				<div 
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock VideoBlock' : 'Hidden ContentBlock VideoBlock' }  
					key = { item.position } 
					dangerouslySetInnerHTML = {{ __html: item.value }}>
				</div>
			)
		} else {
			return (
				<div 
					className = { item.parent === 0 || shownSteps.filter(i => i.id === item.parent).length > 0 ? 'ContentBlock' : 'Hidden ContentBlock' }  
					key = { item.position } 
					dangerouslySetInnerHTML = {{ __html: item.value }}>
				</div>
			)
		}
	}

    return (
        <Layout>
            { guide &&
                <div className='Guide'>
                    <h1>{ guide.name }</h1>
                    { content &&
                        content.map(item => {
                            return handleContent(item)
                        })
                    }
                    { tags &&
                        <React.Fragment>
                            <h3>Avainsanat</h3>
                            { tags.map(tag => {
                                return (
                                    <NavLink style={{ marginRight: '5px' }} key={ tag.id } to='#'>
                                        { tag.name }
                                    </NavLink>
                                )
                            }) }
                        </React.Fragment>
                    }
                </div>
            }
        </Layout>
    )
}

export default ShowGuides